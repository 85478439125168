import { GET_LABELS, GET_LABEL } from '../actions/types'

const initialState = {
  labels: [],
  label: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  labelLoading: true,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_LABELS:
      return {
        ...state,
        labels: payload,
      }
    case GET_LABEL:
      return {
        ...state,
        label: payload,
        labelLoading: false,
      }

    default:
      return state
  }
}
