import { GET_LABELS, GET_LABEL } from "./types";
import axios from "axios";

const PROXY = process.env.REACT_APP_URL;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getLabels = () => async (dispatch) => {
  const res = await axios.get(PROXY + "label/get-label?type=2");

  // localStorage.setItem('labels', JSON.stringify(res.data.result))
  dispatch({
    type: GET_LABELS,
    payload: res.data.result,
  });
};
export const getLabelwithPage =
  (page = 1, limit = 10, search = "") =>
  async (dispatch) => {
    const res = await axios.get(
      PROXY +
        "label/get-label?page=" +
        page +
        "&limit=" +
        limit +
        "&search=" +
        search
    );

    dispatch({
      type: GET_LABEL,
      payload: res.data.result,
    });
    getLabels();
  };

export const addLabel = (label) => async (dispatch) => {
  const body = JSON.stringify(label);
  const res = await axios.post(PROXY + "label/add-label", body, config);
  return res;
};

export const updateLabel = (label) => async (dispatch) => {
  const body = JSON.stringify(label);
  const res = await axios.post(PROXY + "label/update-label", body, config);
  return res;
};

export const deleteLabel = (_id) => async (dispatch) => {
  const res = await axios.delete(PROXY + "label/delete-label/" + _id);
  return res;
};
