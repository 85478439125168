import axios from "axios";
import {
  GET_ALL_PROJECT,
  GET_PROJECT,
  GET_TIMESHEET,
  GET_BILLING_HOUR,
  GET_PROJECT_TOOLS,
  BULK_COUNT,
} from "./types";
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
const PROXY = process.env.REACT_APP_URL;
export const getAllProject =
  (
    page = 1,
    limit = 10,
    search = "",
    flag,
    category = "",
    project_hour = 2,
    type = 1
  ) =>
  async (dispatch) => {
    let res = await axios.get(
      PROXY +
        "project/getAll?page=" +
        page +
        "&limit=" +
        limit +
        "&search=" +
        search +
        "&flag=" +
        flag +
        "&project_hour=" +
        project_hour +
        "&category=" +
        category
      //   '&type=' +
      // type
    );

    dispatch({
      type: GET_ALL_PROJECT,
      payload: res.data.result,
    });
    return res;
  };

// * Add Project
export const addProject = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  const res = await axios.post(PROXY + "project/add", body, config);
  return res;
};

export const editProject = (id, formData) => async (dispatch) => {
  const res = await axios.patch(PROXY + "project/edit/" + id, formData);
  return res;
};

// * get Project by id
export const projectByid = (id) => async (dispatch) => {
  const res = await axios.get(PROXY + "project/get/" + id);
  dispatch({
    type: GET_PROJECT,
    payload: res.data.result,
  });
  return res;
};
export const getTimesheet = (id, date) => async (dispatch) => {
  const timeSheet = await axios.get(
    PROXY +
      "project/timesheet/" +
      id +
      "?from_date=" +
      date.from_date +
      "&to_date=" +
      date.to_date
  );
  // dispatch({
  //   type: GET_TIMESHEET,
  //   payload: timeSheet.data.result,
  // })
  return timeSheet;
};

export const getManagerList = () => async (dispatch) => {
  const res = await axios.get(PROXY + "user/getAll?role=4&type=2");
  return res;
};

export const ManagerProject = (id, data) => async (dispatch) => {
  const body = JSON.stringify(data);
  const res = await axios.put(PROXY + "project/manager/" + id, body, config);
  return res;
};

/**
 * :work -> Add Comment
 */

export const addComment = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  const res = await axios.post(PROXY + "project/comment/", body, config);
  return res;
};

/**
 *  :work -> File Remove
 *
 */
export const removeFile = (id) => async (dispatch) => {
  const res = await axios.delete(PROXY + "project/file/" + id);
  return res;
};
/*
  { Edit Comment}
*/
export const editComment = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  const res = await axios.put(PROXY + "project/comment", body, config);
  return res;
};

/*
  { Get Billing hour}
*/
export const getBillingHour = (id) => async (dispatch) => {
  const res = await axios.get(PROXY + "project/billablehours/" + id);
  dispatch({
    type: GET_BILLING_HOUR,
    payload: res.data.result,
  });
  return res;
};

//  { Billing Hour update}

export const updateBillingHour = (data) => async (dispatch) => {
  let body = JSON.stringify(data);
  const res = await axios.put(PROXY + "project/billablehours", body, config);
  return res;
};

//  { Billabels Tools}

export const getProjectTools = (id, date) => async (dispatch) => {
  const res = await axios.get(
    PROXY +
      "project/tools/" +
      id +
      "?from_date=" +
      date.from_date +
      "&to_date=" +
      date.to_date
  );
  // dispatch({
  //   type: GET_PROJECT_TOOLS,
  //   payload: res.data.result,
  // })
  return res;
};

// :USE // -> projectReorder

export const projectReorder = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  const res = await axios.post(PROXY + "project/reorder", body, config);
  return res;
};
export const editBillableHour = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  const res = await axios.put(PROXY + "tool/hour/update", body, config);
  return res;
};

export const editTimeSheetHour = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  const res = await axios.put(PROXY + "work-log/hour/update", body, config);
  return res;
};

export const bulkNotifications = () => async (dispatch) => {
  const res = await axios.get(`${PROXY}leave/notification-count`);
  dispatch({
    type: BULK_COUNT,
    payload: res.data.result,
  });
};
