import {
  GET_ALL_PROJECT,
  GET_BILLING_HOUR,
  GET_PROJECT_TOOLS,
  GET_PROJECT,
  GET_TIMESHEET,
  BULK_COUNT,
} from '../actions/types'
const initialState = {
  project: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  projectDetails: null,
  projectTimeSheet: [],
  projectBillingHour: [],
  projectTools: [],
  loading: true,
  dataLoading: true,
  toolsLoading: true,
  timeSheetLoading: true,
  bulkCount: {
    leaveCount: 0,
    costCount: 0,
  },
}
export default function projectTools(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_PROJECT:
      return {
        ...state,
        project: payload,
        loading: false,
      }
    case GET_PROJECT:
      return {
        ...state,
        projectDetails: payload,
        dataLoading: false,
      }
    case GET_TIMESHEET:
      return {
        ...state,
        projectTimeSheet: payload,
        timeSheetLoading: false,
      }
    case GET_BILLING_HOUR:
      return {
        ...state,
        projectBillingHour: payload,
        loading: false,
      }
    case GET_PROJECT_TOOLS:
      return {
        ...state,
        projectTools: payload,
        toolsLoading: false,
      }
    case BULK_COUNT:
      return {
        ...state,
        bulkCount: payload,
      }
    default:
      return state
  }
}
