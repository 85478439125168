import { CHANGE_LANG, GET_LANGUAGES } from "./types";
import axios from "axios";

const PROXY = process.env.REACT_APP_URL;
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const getLanguages = () => async (dispatch) => {
  const res = await axios.get(PROXY + "label/get-language");
  dispatch({
    type: GET_LANGUAGES,
    payload: res.data.result,
  });
};

export const addLanguage = (languageObj) => async (dispatch) => {
  const body = JSON.stringify(languageObj);

  const res = await axios.post(PROXY + "label/add-language", body, config);
  return res;
};

export const updateLanguage = (language) => async (dispatch) => {
  const body = JSON.stringify(language);

  const res = await axios.patch(
    PROXY + "label/update-language/" + language._id,
    body,
    config
  );
  return res;
};

export const ChangeLang = (data) => async (dispatch) => {
  dispatch({
    type: CHANGE_LANG,
    payload: data,
  });
};

// export const deleteLanguage = (_id) => async (dispatch) => {
//   await axios
//     .delete(PROXY + "label/delete-language/" + _id)
//     .then((result) => {
//       dispatch(getLanguages());
//       return result;
//     })
//     .catch((err) => {
//       dispatch(setAlert(err.response.data.message, "danger"));
//       return err.response;
//     });
// };
// export const changeLanguageStatus = (language) => async (dispatch) => {
//   const body = JSON.stringify(language);

//   await axios
//     .patch(PROXY + "label/change-status/" + language._id, body, config)
//     .then((result) => {
//       dispatch(setAlert(result.data.message, "success"));
//       dispatch(getLanguages());
//       return result;
//     })
//     .catch((err) => {
//       dispatch(setAlert(err.response.data.message, "danger"));
//       return err.response;
//     });
// };
