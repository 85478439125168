export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const SET_ALERT = 'SET_ALERT'
export const DELETE_ALERT = 'DELETE_ALERT'
export const DELETE_ALL_ALERT = 'DELETE_ALL_ALERT'
export const ADMIN_LOADED = 'ADMIN_LOADED'
export const AUTH_FAIL = 'AUTH_FAIL'
export const LOGOUT = 'LOGOUT'
export const GET_USERS = 'GET_USERS'
export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const GET_ALL_ACTIVE_USERS = 'GET_ALL_ACTIVE_USERS'
export const GET_CLIENT_DETAIL = 'GET_CLIENT_DETAIL'
export const GET_TODOS = 'GET_TODOS'
export const GET_NEW_TODOS = 'GET_NEW_TODOS'
export const ADD_TODO = 'ADD_TODO'
export const GET_TODO = 'GET_TODO'
export const UPDATE_TODO = 'UPDATE_TODO'
export const UPDATE_TODO_STATUS = 'UPDATE_TODO_STATUS'
export const SORT_TODO = 'SORT_TODO'
export const SORT_TODO_CUSTOM = 'SORT_TODO_CUSTOM'
export const DELETE_TODO = 'DELETE_TODO'
export const GET_LANGUAGES = 'GET_LANGUAGES'
export const GET_LABELS = 'GET_LABELS'
export const GET_LABEL = 'GET_LABEL'
export const GET_CATEGORY = 'GET_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const GET_HOBBY = 'GET_HOBBY'
export const UPDATE_HOBBY = 'UPDATE_HOBBY'
export const DELETE_HOBBY = 'DELETE_HOBBY'

export const GET_ACTIVITY = 'GET_ACTIVITY'
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'

export const GET_MAJOR = 'GET_MAJOR'
export const UPDATE_MAJOR = 'UPDATE_MAJOR'
export const DELETE_MAJOR = 'DELETE_MAJOR'
export const GET_USER_GRAPH = 'GET_USER_GRAPH'

export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS'

//
export const CHANGE_LANG = 'CHANGE_LANG'
export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_CLIENT_BY_ID = 'GET_CLIENT_BY_ID'
export const GET_CLIENT_PROJECT = 'GET_CLIENT_PROJECT'

//
export const GET_ALL_PROJECT = 'GET_ALL_PROJECT'
export const GET_PROJECT = 'GET_PROJECT'
export const GET_TIMESHEET = 'GET_TIMESHEET'
export const GET_PROJECT_TOOLS = 'GET_PROJECT_TOOLS'

//

export const GET_ALL_USER = 'GET_ALL_USER'
export const GET_ALL_HOLIDAY = 'GET_ALL_HOLIDAY'
//
export const GET_ALL_WORK_LOGS = 'GET_ALL_WORK_LOGS'

export const GET_ALL_LEAVES = 'GET_ALL_LEAVES'

//
export const GET_BILLING_HOUR = 'GET_BILLING_HOUR'
//
export const GET_ALL_TIMESHEET = 'GET_ALL_TIMESHEET'
export const TIMESHEET_USER = 'TIMESHEET_USER'
//
export const GET_ALL_TOOLS = 'GET_ALL_TOOLS'
export const GET_TOOL = 'GET_TOOL'

//
export const GET_ALL_SELLER = 'GET_ALL_SELLER'

//
export const GET_ALL_BillING_GOODS = 'GET_ALL_BillING_GOODS'
export const GET_ALL_PROJECT_BILLABLE_GOODS = 'GET_ALL_PROJECT_BILLABLE_GOODS'

//
export const GET_PAYMENT = 'GET_PAYMENT'

//
export const BULK_COUNT = 'BULK_COUNT'
