import { GET_ALL_BillING_GOODS, GET_ALL_PROJECT_BILLABLE_GOODS } from '../actions/types'
const initialState = {
  goodsList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  projectGoodsList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },

  loading: true,
}
export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_BillING_GOODS:
      return {
        ...state,
        goodsList: payload,
        loading: false,
      }
    case GET_ALL_PROJECT_BILLABLE_GOODS:
      return {
        ...state,
        projectGoodsList: payload,
      }

    default:
      return state
  }
}
