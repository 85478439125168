import { GET_PAYMENT } from '../actions/types'
const initialState = {
  payment: null,
  loading: true,
}

export default function payment(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_PAYMENT:
      return {
        ...state,
        payment: payload,
        loading: false,
      }

    default:
      return state
  }
}
