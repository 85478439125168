import { GET_CLIENTS, GET_CLIENT_BY_ID, GET_CLIENT_PROJECT } from '../actions/types'
const initialState = {
  client: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  clientByProject: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  clientDetails: null,
  loading: true,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_CLIENTS:
      return {
        ...state,
        client: payload,
        loading: false,
      }
    case GET_CLIENT_BY_ID:
      return {
        ...state,
        clientDetails: payload,
      }
    case GET_CLIENT_PROJECT:
      return {
        ...state,
        clientByProject: payload,
      }
    default:
      return state
  }
}
