import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ADMIN_LOADED,
  AUTH_FAIL,
  LOGOUT,
} from '../actions/types'
const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  loading: true,
  admin: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ADMIN_LOADED:
      localStorage.setItem('role', payload.admin.role)
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        admin: payload.admin,
      }

    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token)

      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      }
    case LOGIN_FAIL:
    case AUTH_FAIL:
    case LOGOUT:
      localStorage.removeItem('token')
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      }
    default:
      return state
  }
}
