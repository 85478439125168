import { GET_ALL_TIMESHEET, TIMESHEET_USER } from '../actions/types'

const initialState = {
  timeSheet: [],
  userTimeSheet: [],
  loading: true,
}
export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_TIMESHEET:
      return {
        ...state,
        timeSheet: payload,
        loading: false,
      }
    case TIMESHEET_USER:
      return {
        ...state,
        userTimeSheet: payload,
        loading: false,
      }

    default:
      return state
  }
}
