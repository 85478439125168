import { GET_CATEGORY } from '../actions/types'
const initialState = {
  category: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },

  loading: true,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_CATEGORY:
      return {
        ...state,
        category: payload,
        loading: false,
      }

    default:
      return state
  }
}
