import { combineReducers } from 'redux'
import authReducers from './authReducers.js'
import alertReducers from './alertReducer'
import languageReducer from './languageReducer.js'
import labelReducer from './labelReducer.js'
import clientReducer from './clientReducer'
import projectReducer from './projectReducer'
import userReducer from './userReducer'
import categoryReducer from './categoryReducer'
import toolcategoryReducer from './toolcategoryReducer'
import holidayReducer from './holidayReducer'
import workLogReducer from './workLogReducer'
import leaveReducer from './leaveReducer'
import timeSheetReducer from './timeSheetReducer'
import toolsReducer from './toolsReducer'
import SellerReducer from './sellerReducer'
import BillingGoodsReducers from './billingGoodsReducers'
import paymentReducers from './paymentReducers'

export default combineReducers({
  auth: authReducers,
  alert: alertReducers,
  languages: languageReducer,
  labels: labelReducer,
  client: clientReducer,
  project: projectReducer,
  user: userReducer,
  category: categoryReducer,
  holiday: holidayReducer,
  workLogs: workLogReducer,
  leaves: leaveReducer,
  timesheet: timeSheetReducer,
  toolcategory: toolcategoryReducer,
  tools: toolsReducer,
  sellers: SellerReducer,
  billingGoods: BillingGoodsReducers,
  payment: paymentReducers,
})
