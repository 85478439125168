import { GET_LANGUAGES, CHANGE_LANG } from '../actions/types'

const initialState = {
  languages: [],
  lang: '',
  loading: true,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_LANGUAGES:
      return {
        ...state,
        languages: payload,
        loading: false,
      }
    case CHANGE_LANG:
      return {
        ...state,
        lang: payload,
      }

    default:
      return state
  }
}
